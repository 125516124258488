import { Grid, Stack } from '@mui/material';
import { theme } from '../../config/theme';
import ImgMediaCard from '../../components/ImgMediaCard';
import TitleSection from '../../components/TitleSection';
import { projects } from '../../config/projectsList';

export default function Projects() {
  return (
    <Stack
      flexDirection="column"
      gap={3}
      width="100%"
      height="auto"
      style={{
        backgroundColor: theme.palette.primary.main,
        transition: '0.5s all',
      }}
      id="Projects"
      paddingBottom={3}
    >
      <TitleSection title="PROJECTS" subtitle="MY WORK" data-aos="fade-up" />

      {/* <Stack
        flexDirection={{ xs: 'column', xl: 'row' }}
        alignItems={{ xs: 'center' }}
        justifyContent="space-evenly"
        width="100%"
        flexWrap={{ xs: 'nowrap', md: 'wrap' }}
        data-aos="fade-up"
      > */}
      <Grid container spacing={2} columns={3}>
        <Grid
          item
          xs={3}
          xl={1}
          display="flex"
          justifyContent="center"
          data-aos="fade-up"
        >
          <ImgMediaCard cardInfo={projects.travelTrace} />
        </Grid>
        <Grid
          item
          xs={3}
          xl={1}
          display="flex"
          justifyContent="center"
          data-aos="fade-up"
        >
          <ImgMediaCard cardInfo={projects.yourDressCode} />
        </Grid>
        <Grid
          item
          xs={3}
          xl={1}
          display="flex"
          justifyContent="center"
          data-aos="fade-up"
        >
          <ImgMediaCard cardInfo={projects.iMenu} />
        </Grid>
        <Grid
          item
          xs={3}
          xl={1}
          display="flex"
          justifyContent="center"
          data-aos="fade-up"
        >
          <ImgMediaCard cardInfo={projects.testMeQuiz} />
        </Grid>
        <Grid
          item
          xs={3}
          xl={1}
          display="flex"
          justifyContent="center"
          data-aos="fade-up"
        >
          <ImgMediaCard cardInfo={projects.spaceTurism} />
        </Grid>
        <Grid
          item
          xs={3}
          xl={1}
          display="flex"
          justifyContent="center"
          data-aos="fade-up"
        >
          <ImgMediaCard cardInfo={projects.netflix} />
        </Grid>
        <Grid
          item
          xs={3}
          xl={1}
          display="flex"
          justifyContent="center"
          data-aos="fade-up"
        >
          <ImgMediaCard cardInfo={projects.footballStats} />
        </Grid>
        <Grid
          item
          xs={3}
          xl={1}
          display="flex"
          justifyContent="center"
          data-aos="fade-up"
        >
          <ImgMediaCard cardInfo={projects.nowMeteo} />
        </Grid>
        <Grid
          item
          xs={3}
          xl={1}
          display="flex"
          justifyContent="center"
          data-aos="fade-up"
        >
          <ImgMediaCard cardInfo={projects.ecommerce} />
        </Grid>
      </Grid>
    </Stack>
  );
}
