import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

interface TitleSectionProps {
  title: string;
  subtitle: string;
}

export default function TitleSection({ title, subtitle }: TitleSectionProps) {
  return (
    <Stack
      flexDirection="row"
      justifyContent="center"
      position="relative"
      height={{ xs: 'auto', md: '200px' }}
    >
      <Typography
        component="h3"
        variant="h3"
        color="lightgray"
        style={{ opacity: '0.1' }}
      >
        {title}
      </Typography>
      <Stack
        flexDirection="row"
        justifyContent="center"
        position="absolute"
        height="100%"
        alignItems="center"
      >
        <Typography component="h4" variant="h4" color="white">
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  );
}

TitleSection.defaultProps = {
  title: '',
  subtitle: '',
};

TitleSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
