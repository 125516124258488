import { ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import Aos from 'aos';
import './App.scss';
import AboutMe from './section/AboutMe';
import Appbar from './components/Appbar';
import MainSection from './section/MainSection';
import Projects from './section/Projects';
import Skills from './section/Skills';
import { theme } from './config/theme';
import Contact from './section/Contact';
import LanguageProvider from './context/Language';

function App() {
  useEffect(() => {
    Aos.init({ duration: 1200, once: true, offset: 300 });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <>
          <Appbar />
          <MainSection />
          <AboutMe />
          <Skills />
          <Projects />
          <Contact />
        </>
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
