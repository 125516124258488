import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

interface ISingleSkill {
  img: string;
  skillsTitle: string;
}

export default function SingleSkill({ img, skillsTitle }: ISingleSkill) {
  return (
    <Stack flexDirection="row" alignItems="center">
      <img src={img} alt="html" style={{ width: '50px' }} />
      <Typography component="h5" variant="h5" color="white">
        {skillsTitle}
      </Typography>
    </Stack>
  );
}

SingleSkill.defaultProps = {
  img: '',
  skillsTitle: '',
};

SingleSkill.propTypes = {
  img: PropTypes.string,
  skillsTitle: PropTypes.string,
};
