import { Stack } from '@mui/material';
import { theme } from '../../config/theme';
import SingleSkill from '../../components/SingleSkill';
import TitleSection from '../../components/TitleSection';
import imgHtml from '../../assets/html.png';
import imgCss from '../../assets/css.png';
import imgJs from '../../assets/js.png';
import imgSass from '../../assets/sass.png';
import imgReact from '../../assets/react.png';
import imgTypescript from '../../assets/typescript.png';
import imgGit from '../../assets/git.png';
import imgGithub from '../../assets/github.png';
import imgSql from '../../assets/sql.png';

export default function Skills() {
  return (
    <Stack
      flexDirection="column"
      width="100%"
      height={{ xs: 'auto', md: '500px' }}
      style={{ backgroundColor: theme.palette.secondary.main }}
      id="Skills"
    >
      <TitleSection title="SKILLS" subtitle="WHAT I DO" />
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'center', md: 'space-evenly' }}
        alignItems={{ xs: 'center', md: 'flex-start' }}
        width="100%"
        height="100%"
        gap={{ xs: 3 }}
        paddingBottom={3}
      >
        <Stack
          flexDirection="column"
          justifyContent="space-between"
          height="80%"
          gap={{ xs: 3 }}
          width={{ xs: '200px' }}
          data-aos="zoom-in"
        >
          <SingleSkill img={imgHtml} skillsTitle="HTML" />
          <SingleSkill img={imgCss} skillsTitle="CSS" />
          <SingleSkill img={imgJs} skillsTitle="JAVASCRIPT" />
        </Stack>
        <Stack
          flexDirection="column"
          justifyContent="space-between"
          height="80%"
          gap={{ xs: 3 }}
          width={{ xs: '200px' }}
          data-aos="zoom-in"
        >
          <SingleSkill img={imgSass} skillsTitle="SASS" />
          <SingleSkill img={imgReact} skillsTitle="REACT" />
          <SingleSkill img={imgTypescript} skillsTitle="TYPESCRIPT" />
        </Stack>
        <Stack
          flexDirection="column"
          justifyContent="space-between"
          height="80%"
          gap={{ xs: 3 }}
          width={{ xs: '200px' }}
          data-aos="zoom-in"
        >
          <SingleSkill img={imgGit} skillsTitle="GIT" />
          <SingleSkill img={imgGithub} skillsTitle="GITHUB" />
          <SingleSkill img={imgSql} skillsTitle="SQL" />
        </Stack>
      </Stack>
    </Stack>
  );
}
