import PropTypes from 'prop-types';
import { useState } from 'react';
import './index.scss';

interface TextareaProps {
  // eslint-disable-next-line no-undef
  onChange: React.Dispatch<React.SetStateAction<string>>;
}

export default function Textarea({ onChange }: TextareaProps) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <textarea
      style={{
        backgroundColor: '#232a31',
        color: 'white',
        borderWidth: '2px',
        borderColor: isFocused ? '#80bdff' : 'rgb(40 45 49)',
        outline: 'none',
        marginTop: 30,
        height: '150px',
        resize: 'none',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '15px',
        width: '100%',
      }}
      placeholder="Contattami ora"
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}

Textarea.defaultProps = {
  onChange: () => {},
};

Textarea.propTypes = {
  onChange: PropTypes.func,
};
