import { useState } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TextField from '@mui/material/TextField';
import TitleSection from '../../components/TitleSection';
import { theme } from '../../config/theme';
import Textarea from '../../components/Textarea';
import { useLanguageContext } from '../../context/Language';
import { textContact } from '../../config/text';

export default function Contact() {
  const [isFocused, setIsFocused] = useState(false);
  const [object, setObject] = useState('');
  const [bodyText, setBodyText] = useState('');
  const { language } = useLanguageContext();

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleOnSubmit = () => {
    const body = bodyText;
    const mailToLink = `mailto:niccolonaso@yahoo.it?subject=${encodeURIComponent(
      object,
    )}&body=${encodeURIComponent(body)}`;

    window.open(mailToLink);
  };

  return (
    <Stack
      flexDirection="column"
      width="100%"
      height="600px"
      style={{ backgroundColor: theme.palette.secondary.main }}
      id="Contact"
    >
      <TitleSection title="CONTACT" subtitle="MY ADDRESS" />

      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-evenly"
        alignItems={{ xs: 'center', md: 'flex-start' }}
        data-aos="fade-up"
      >
        <Stack
          flexDirection="column"
          width={{ xs: '80%', md: '20%' }}
          marginLeft={{ xs: 0, md: '10%' }}
        >
          <Typography variant="h5" component="h5" color="white">
            {language === 'it'
              ? textContact.contacts.it
              : textContact.contacts.en}
          </Typography>
          <a
            href="mailto:niccolonaso@yahoo.it"
            style={{ textDecoration: 'none' }}
          >
            <Stack flexDirection="row" alignItems="center">
              <EmailIcon color="info" />
              <Typography variant="body1" component="span" color="white">
                niccolonaso@yahoo.it
              </Typography>
            </Stack>
          </a>

          <a
            href="https://www.linkedin.com/in/niccol%C3%B2-naso-888039178/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Stack flexDirection="row" alignItems="center">
              <LinkedInIcon color="info" />
              <Typography variant="body1" component="span" color="white">
                LinkedIn
              </Typography>
            </Stack>
          </a>

          <a
            href="https://github.com/LordNik10"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Stack flexDirection="row" alignItems="center">
              <GitHubIcon color="info" />
              <Typography variant="body1" component="span" color="white">
                Github
              </Typography>
            </Stack>
          </a>
        </Stack>

        <Stack
          flexDirection="column"
          width={{ xs: '80%', md: '50%' }}
          marginTop={{ xs: 2, md: 0 }}
        >
          <form
            style={{ width: '100%', height: '100%' }}
            action="mailto:niccolonaso@yahoo.it"
            method="post"
            encType="text/plain"
            onSubmit={handleOnSubmit}
          >
            <Typography
              variant="h5"
              component="h5"
              color="white"
              margin={0}
              marginBottom={2}
            >
              {language === 'it'
                ? textContact.contactMe.it
                : textContact.contactMe.en}
            </Typography>

            <Stack flexDirection="row" gap="50px" width="100%">
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                gap={3}
              >
                <TextField
                  color="success"
                  sx={{
                    input: {
                      color: 'white',
                      backgroundColor: '#232a31',
                    },
                  }}
                  InputLabelProps={{
                    style: { color: isFocused ? '#80bdff' : '#999' },
                  }}
                  placeholder="Oggetto"
                  label="Oggetto"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setObject(e.target.value);
                  }}
                  fullWidth
                />
              </Stack>
            </Stack>
            <Textarea onChange={setBodyText} />
            <Stack
              flexDirection="row"
              justifyContent="center"
              width="100%"
              marginTop={2}
            >
              <Button
                type="submit"
                variant="contained"
                color="error"
                sx={{ borderRadius: '10px', width: 100 }}
              >
                {language === 'it'
                  ? textContact.btnSubmit.it
                  : textContact.btnSubmit.en}
              </Button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </Stack>
  );
}
