import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import './index.scss';
import { useLanguageContext } from '../../context/Language';

interface IImgMediaCard {
  cardInfo: {
    img: string;
    title: string;
    textIT: string;
    textEN: string;
    link: string;
  };
}

export default function ImgMediaCard({ cardInfo }: IImgMediaCard) {
  const { language } = useLanguageContext();

  return (
    <a
      href={cardInfo.link}
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <Stack
        sx={{
          width: { xs: 270, sm: 345 },
          position: 'relative',
          cursor: 'pointer',
          height: 370,
        }}
      >
        <Stack
          sx={{ opacity: 0, backgroundColor: 'black', position: 'absolute' }}
          className="card"
          width="100%"
          height="100%"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography component="h5" variant="h5" color="white">
            {cardInfo.title}
          </Typography>
        </Stack>
        <Card sx={{ width: '100%', height: '100%' }}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="200"
            image={cardInfo.img}
          />
          <hr style={{ width: '100%', margin: 0 }} />
          <CardContent style={{ paddingTop: 10 }}>
            <Typography gutterBottom variant="h5" component="div">
              {cardInfo.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {language === 'it' ? cardInfo.textIT : cardInfo.textEN}
            </Typography>
          </CardContent>
        </Card>
      </Stack>
    </a>
  );
}

ImgMediaCard.defaultProps = {
  cardInfo: {},
};

ImgMediaCard.propTypes = {
  cardInfo: PropTypes.shape({
    img: PropTypes.string,
    title: PropTypes.string,
    textIT: PropTypes.string,
    textEN: PropTypes.string,
    link: PropTypes.string,
  }),
};
