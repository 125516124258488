// eslint-disable-next-line no-use-before-define
import React, {
  useState,
  useMemo,
  createContext,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

interface IConfigureContext {
  language: string;
  handleLanguage: (param: string) => void;
}

const configureContext: IConfigureContext = {
  language: 'it',
  handleLanguage: () => {},
};

const LanguageContext = createContext(configureContext);

export function useLanguageContext() {
  return useContext(LanguageContext);
}

function LanguageProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const [language, setLanguage] = useState<string>('it');

  const handleLanguage = useCallback((languageValue: string) => {
    setLanguage(languageValue);
  }, []);

  const handleLanguageMemo = useMemo(
    () => ({ language, handleLanguage }),
    [language, handleLanguage],
  );

  return (
    <LanguageContext.Provider value={handleLanguageMemo}>
      {children}
    </LanguageContext.Provider>
  );
}

export default LanguageProvider;

LanguageProvider.defaultProps = {
  children: null,
};

LanguageProvider.propTypes = {
  children: PropTypes.element,
};
