import { Stack, Typography } from '@mui/material';
import Typewriter from 'typewriter-effect';
import backgroundImg from '../../assets/2.jpg';
import './index.scss';

export default function MainSection() {
  return (
    <Stack
      width="100%"
      height={{ md: 700, xs: 500 }}
      sx={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      id="main"
    >
      <Stack
        flexDirection="column"
        color="white"
        marginLeft="10%"
        marginTop={{ xs: '20%', sm: '10%' }}
      >
        <Typography component="h1" variant="h1" margin={0}>
          <Typewriter
            options={{
              strings: ['Niccolò Naso'],
              autoStart: true,
              loop: true,
            }}
          />
        </Typography>

        <Typography component="h2" variant="h2" margin={0} fontWeight={200}>
          A Front End Developer
        </Typography>
      </Stack>
    </Stack>
  );
}
