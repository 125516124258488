import imgYourDressCode from '../../assets/yourdresscode.png';
import imgIMenu from '../../assets/imenu4.png';
import imgTestMeQuiz from '../../assets/testmequiz.png';
import imgSpaceTurism from '../../assets/space.png';
import imgNetflix from '../../assets/netflix.png';
import imgFootballStats from '../../assets/footballstats.png';
import imgNowMeteo from '../../assets/nowmeteo.png';
import imgEcommerce from '../../assets/ecommerce.png';
import imgTravelTrace from '../../assets/traveltrace.png';

export const projects = {
  travelTrace: {
    title: 'Travel Trace',
    img: imgTravelTrace,
    textIT:
      "Questa è una web app che permette all'utente di segnare tutti i posti che ha visitato nel mondo su una mappa",
    textEN:
      'This is a web app that allows the user to mark all the places he has visited in the world on a map',
    link: 'https://traveltrace.netlify.app/',
  },
  yourDressCode: {
    title: 'Your Dress Code',
    img: imgYourDressCode,
    textIT:
      "YourDressCode è un ecommerce che ho realizzato per una ipotetica azienda di abbigliamento, in cui l' utente può registrarsi alla piattaforma, effettuare il login, aggiungere articoli al carrello e vedere il suo contenuto ",
    textEN:
      'YourDressCode is an ecommerce that I created for a hypothetical clothing company, in which the user can register on the platform, log in, add items to the cart and see its contents',
    link: 'https://yourdresscode.netlify.app/',
  },
  iMenu: {
    title: 'IMenu',
    img: imgIMenu,
    textIT:
      'Questo è un menù digitale pensato per una versione mobile ma visibile anche su formato desktop, pensato per ristoranti o pizzerie, con la possibilità di essere collegato ad un qr e velozziare la lettura del menù al ristorante e favorire il risparmio di carta',
    textEN:
      'This is a digital menu designed for a mobile version but also visible on a desktop format, designed for restaurants or pizzerias, with the possibility of being connected to a qr and speeding up the reading of the menu in the restaurant and favoring paper savings',
    link: 'https://imenutemplate.netlify.app/',
  },
  testMeQuiz: {
    title: 'TestMeQuiz',
    img: imgTestMeQuiz,
    textIT:
      "Questo è un quiz che ho realizzato per testare la cultura generale del giocatore in diverse categorie. E' dotato di un timer e di un contatore del punteggio di ogni risposta esatta",
    textEN:
      'This is a quiz I made to test the general knowledge of the gamer in different categories. It is equipped with a timer and a score counter for each correct answer',
    link: 'https://lordnik10.github.io/TestMeQuiz/',
  },
  spaceTurism: {
    title: 'Space Turism',
    img: imgSpaceTurism,
    textIT: `Space Turism è un sito web informativo. Ho realizzato questo sito come challenge per una piattaforma, "Front End Mentor" `,
    textEN: `Space Tourism is an informational website. I created this site as a challenge for a platform, "Front End Mentor"`,
    link: 'https://lordnik10.github.io/Space-Turism/',
  },
  netflix: {
    title: 'Netflix Clone',
    img: imgNetflix,
    textIT:
      'Questo progeto che ho realizzato è un clone della piattaforma netflix',
    textEN: 'This project I made is a clone of the Netflix platform',
    link: 'https://lordnik10.github.io/NetflixClone/',
  },
  footballStats: {
    title: 'Football Stats',
    img: imgFootballStats,
    textIT:
      'Questa è una piattaforma ideate per visualizzare le statistiche e informazioni di ogni squadra di calcio dei 3 maggiori campionati europei',
    textEN:
      'This is a platform designed to display the statistics and information of each football team from the 3 major European leagues',
    link: 'https://lordnik10.github.io/FootballStats/',
  },
  nowMeteo: {
    title: 'NowMeteo',
    img: imgNowMeteo,
    textIT:
      'Questo sito web utilizzando le api del meteo permettere di visualizzare le previsioni meteo per una qualsiasi città inserita nella barra di ricerca ',
    textEN:
      'This website using the weather bees allows you to view the weather forecast for any city entered in the search bar',
    link: 'https://lordnik10.github.io/Meteo/',
  },
  ecommerce: {
    title: 'Ecommerce',
    img: imgEcommerce,
    textIT:
      'Questa è una pagina di visualizzazione di un articolo di un ipotetico ecommerce realizzata per una challenge per una piattaforma "Front End Mentor"',
    textEN:
      'This is a visualization page of an article of a hypothetical ecommerce created for a challenge for a "Front End Mentor" platform',
    link: 'https://lordnik10.github.io/Ecommerce-product/',
  },
};
