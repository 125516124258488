import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000000de',
    },
    secondary: {
      main: '#343a40',
      contrastText: '#000',
    },
    error: {
      main: '#20c997',
      contrastText: '#fff',
    },
    warning: {
      main: '#fbc02d',
      contrastText: '#000',
    },
    success: {
      main: '#80bdff',
    },
    info: {
      main: '#ffffff',
    },
    bgDark: {
      main: '#3C3C3C',
      contrastText: '#fff',
    },
    bgLight: {
      main: '#fff',
      contrastText: '#000',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1600,
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          margin: '4px',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: 'white !important',
        },
        icon: {
          color: 'white !important',
        },
      },
    },
    MuiInput: {
      input: {
        '&::placeholder': {
          color: 'gray',
        },
        color: 'white',
      },
    },
  },
});

theme.typography.h1 = {
  [theme.breakpoints.up('xs')]: {
    fontSize: '35px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '60px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '80px',
  },
};

theme.typography.h2 = {
  [theme.breakpoints.up('xs')]: {
    fontSize: '23px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '40px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '50px',
  },
};

theme.typography.h3 = {
  [theme.breakpoints.up('xs')]: {
    fontSize: '40px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '40px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '120px',
  },
};

theme.typography.h4 = {
  [theme.breakpoints.up('xs')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '25px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '60px',
  },
};

theme.typography.h5 = {
  [theme.breakpoints.up('xs')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
  },
};

theme.typography.h6 = {
  [theme.breakpoints.up('xs')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
};

theme.typography.body1 = {
  [theme.breakpoints.up('xs')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
};
