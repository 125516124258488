import { Stack, Typography } from '@mui/material';
import { theme } from '../../config/theme';
import aboutMeImg from '../../assets/aboutMeImg.png';
import TitleSection from '../../components/TitleSection';
import { textAboutMe } from '../../config/text';
import { useLanguageContext } from '../../context/Language';
import './index.scss';

export default function AboutMe() {
  const { language } = useLanguageContext();
  return (
    <Stack
      flexDirection="column"
      width="100%"
      height="auto"
      style={{ backgroundColor: theme.palette.primary.main }}
      id="About me"
      paddingBottom={2}
    >
      <TitleSection title="ABOUT ME" subtitle="KNOW ME" />

      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        width="100%"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Typography
          component="span"
          variant="body1"
          color="white"
          width={{ xs: '70%', sm: '50%', md: '30%' }}
          data-aos="fade-right"
        >
          {language === 'it' ? textAboutMe.it : textAboutMe.en}
        </Typography>
        <img
          src={aboutMeImg}
          alt="developer"
          className="img-aboutme"
          data-aos="fade-left"
        />
      </Stack>
    </Stack>
  );
}
