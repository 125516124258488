// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { Link } from 'react-scroll';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Stack } from '@mui/material';
import { theme } from '../../config/theme';
import { useLanguageContext } from '../../context/Language';
import italy from '../../assets/italy.png';
import english from '../../assets/english.png';

const pages = ['About me', 'Skills', 'Projects', 'Contact'];

function Appbar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );

  const { handleLanguage } = useLanguageContext();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const changeLanguage = (value: string) => {
    handleLanguage(value);
  };

  return (
    <AppBar position="fixed" color="primary">
      <Container maxWidth={'xxl' as 'lg'}>
        <Toolbar
          disableGutters
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Link to="main" smooth duration={1000}>
            <Typography
              variant="h6"
              noWrap
              component="span"
              sx={{
                mr: 2,
                display: { xs: 'none', lg: 'flex' },
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              Niccolò Naso
            </Typography>
          </Link>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', lg: 'none' },
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', lg: 'none' },
              }}
            >
              {pages.map((page) => (
                <Link to={page} smooth duration={1000} key={page}>
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
            <Stack
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="33%%"
            >
              <Link to="main" smooth duration={1000}>
                <Typography
                  variant="h6"
                  noWrap
                  component="span"
                  sx={{
                    mr: 2,
                    display: { xs: 'flex', lg: 'none' },
                    flexGrow: 1,
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    width: '100%',
                  }}
                >
                  Niccolò Naso
                </Typography>
              </Link>
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Button
                onClick={() => changeLanguage('it')}
                sx={{ padding: 0, width: 20, height: 10, minWidth: 0 }}
              >
                <img
                  src={italy}
                  alt="italy"
                  style={{ width: 20, height: 10 }}
                />
              </Button>
              <Button
                onClick={() => changeLanguage('eng')}
                sx={{ padding: 0, width: 20, height: 10, minWidth: 0 }}
              >
                <img
                  src={english}
                  alt="english"
                  style={{ width: 20, height: 10 }}
                />
              </Button>
            </Stack>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', lg: 'flex' },
              justifyContent: 'flex-end',
            }}
          >
            {pages.map((page) => (
              <Link to={page} smooth duration={1000} key={page}>
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 600,
                    fontSize: theme.typography.h6,
                    marginRight:
                      page === pages[pages.length - 1] ? 0 : theme.spacing(5),
                  }}
                >
                  {page}
                </Button>
              </Link>
            ))}
            <Stack flexDirection="row" alignItems="center" marginLeft={4}>
              <Button
                onClick={() => changeLanguage('it')}
                sx={{ padding: 0, width: 30, height: 20 }}
              >
                <img
                  src={italy}
                  alt="italy"
                  style={{ width: 30, height: 20 }}
                />
              </Button>
              <Button
                onClick={() => changeLanguage('eng')}
                sx={{ padding: 0, width: 30, height: 20 }}
              >
                <img
                  src={english}
                  alt="english"
                  style={{ width: 30, height: 20 }}
                />
              </Button>
            </Stack>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Appbar;
