export const textAboutMe = {
  it: 'Sono Niccolò Naso, un Front End Developer con una grande passione per il web. Adoro creare siti web moderni e accattivanti in grado di fornire un esperienza utente di alta qualità. Ciò che mi contraddistingue è la mia voglia di sperimentare e cercare sempre di imparare cose nuove. Per questo sono costantemente impegnato in progetti personali che mi permettono di mettere alla prova le mie capacità e migliorare sempre di più.',
  en: "I'm Niccolò Naso, a Front End Developer with a great passion for the web. I love creating modern and eye catching websites that can provide a high quality user experience. What distinguishes me is my desire to experiment and always try to learn new things. This is why I am constantly engaged in personal projects that allow me to test my skills and improve more and more.",
};

export const textContact = {
  contacts: {
    it: 'Contatti',
    en: 'Contact',
  },
  contactMe: {
    it: 'Contattami',
    en: 'Contact Me',
  },
  btnSubmit: {
    it: 'Invia',
    en: 'Send',
  },
};
